#root {
  display: flex;
  flex-direction: column;
}

#root,
#root>* {
  min-height: 100dvh;
  max-height: 100dvh;
  overflow: hidden;
}

main {
  padding-block: 0;
  overflow: hidden;
}

.main-container {
  padding-block-start: 1.5rem;
  padding-block-end: 1.5rem;
}

/* TODO: Gestire meglio!!
@media (min-width: var(--bs-breakpoint-lg)) { */
@media (min-width: 992px) {
  .main-container {
    padding-block-start: 3rem;
    padding-block-end: 3rem;
  }
}

.gSpace-075 {
  --g-space: 0.75rem;
}

.gSpace-0625 {
  --g-space: 0.625rem;
}

.gSpace-1875 {
  --g-space: 1.875rem;
}

.mbSpace-0875 {
  --mb-space: 0.875rem;
}

.mtSpace-06875 {
  --mt-space: 0.6875rem;
}

.mtSpace-075 {
  --mt-space: 0.75rem;
}

.mtSpace-18125 {
  --mt-space: 1.8125rem;
}

.mtSpace-1875 {
  --mt-space: 1.875rem;
}

.mtSpace-125 {
  --mt-space: 1.25rem;
}

.mtSpace-25 {
  --mt-space: 2.5rem;
}

.pxSpace-0375 {
  --px-space: 0.375rem;
}

.txtOpacty05 {
  --text-opacity: .5;
}

a.light {
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.form-check-label.light {
  font-weight: 400;
}

footer {
  padding-top: 2rem;
}

.box-cart-upper-shadow {
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, .12));
  content: "";
  height: 2rem;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.expandible-cart {
  margin-left: -1rem;
  margin-right: -1rem;
}


.box-cart {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: unset;
  right: 0;
}

.fade-out {
  opacity: 0;
  transition: opacity 0s ease-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 1.5s ease-in;
}

.cart-hr-container {
  width: 95%;
  margin-left: 15px;
  padding-right: 15px;
}

.cart-expander {
  display: none;
}

.cart-has-items {
  display: block;
}

@media (min-width:992px) {
  .box-cart {
    flex-direction: column-reverse;
    gap: 2rem;
    position: static;
    padding-top: 0;
  }
}

.box-cart.sticky-top-desktop {
  top: 32px;
}

.category-select-wrapper {
  position: unset;
}

.btn.btn-modal {
  width: 4rem;
}

.btn.btn-modal.btn-modal-large {
  width: 6rem;
}

/* div.form-control {
  height: 46px;
} */

div.form-control.placeholder {
  color: color;
}

.sheet-wrapper {
  height: 100dvh;
  width: 100dvw;
  top: 0dvh;
}

.sheet-wrapper.sheet-bottom .sheet {
  /* height: calc(100dvh - 3.3125rem); */
  height: 100dvh;
}

.list-group-item {
  color: #26267d;
  font-weight: 700;
  opacity: .7;
  cursor: pointer;
}

.list-group-item.full-opacity {
  opacity: 1;
}


/* .sheet-wrapper.sheet-bottom {
  transform: translateY(100dvh);
}

.sheet-wrapper.sheet-open {
  transform: translateY(0dvh);
}

@media (min-width: 992px) {
  .sheet-wrapper.sheet-bottom {
    transform: translateX(-100dvw);
  }

  .sheet-wrapper.sheet-open {
    transform: translateX(0dvw);
  }
} */


.price-margin {
  margin-left: 1rem;
}

.shadow-box {
  border-radius: 0%;
}

.location-form::placeholder {
  opacity: 1 !important;
}

@media (max-width: 991px) {
  .menu.menu-collapse-lg .menu-nav {
    height: calc(100dvh - 56px);
    width: 100dvw;
  }

  .cart-expander {
    display: none;
  }

  .cart-has-items {
    display: block;
  }

  .cart-hr-container {
    display: none;
  }

  .lateral-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .price-margin {
    margin-left: 0;
  }

  .box-cart-container {
    padding-bottom: 2rem;
  }

  .chk-location-input-group.input-group .form-control {
    border-right-width: 2px;
    border-bottom-left-radius: 0px;
    border-bottom: 0px;
  }

  .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control {

    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    border-bottom-right-radius: 0px;

  }

  .input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
  }

  .input-group .btn {
    align-items: center;
    display: flex;
    font-size: 1.125rem;
    font-weight: 500;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    flex-direction: column;
  }

  .mobile-list-group {
    top: 116px;
  }

  .input-group>.form-control,
  .input-group>.form-floating,
  .input-group>.form-select {
    width: 100%;
    color: black;
  }

  .input-group>.btn:not(:first-child) {
    border-bottom-left-radius: .5rem !important;
    border-bottom-right-radius: .5rem;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px;
    margin-left: 0px !important;
  }



  .arrow-back {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    >.arrow-back__subtitle {
      margin-top: 0.5rem;
      margin-left: 1rem
    }
  }
}

.menu-item>.btn {
  text-transform: uppercase
}

.btn-fifth {
  --bs-btn-color: var(--secondary);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-color: var(--fifth);
}

.autocomplete.location-yellow .form-control {
  border: 1px solid var(--fifth) !important;
  line-height: 1;
  padding: .8125rem 2rem .8125rem 1.75rem;
  height: 56px;
}

.autocomplete.location-yellow.input-group .btn {
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  font-weight: 800;
  height: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  z-index: 6;
}

.autocomplete.location-yellow.input-group>.btn:not(:first-child) {
  border-bottom-left-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.autocomplete.location-yellow.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n+3)>.form-select,
.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 576px) {
  .autocomplete.location-yellow.input-group>.btn:not(:first-child) {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 4rem !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4rem !important;
    margin-left: -.25rem !important;
  }

  .autocomplete.location-yellow.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {

    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
}

.autocomplete.location-yellow .btn:disabled {
  opacity: 1;
}

.autocomplete.location-yellow .list-group-item {
  opacity: 1;
}